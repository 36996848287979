import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Icon } from '@qga/roo-ui/components';
import { useSelector } from 'react-redux';
import { getCampaignUrl, getCampaignUrlLabel, getCampaignTracking } from 'store/campaign/campaignSelectors';
import { useDataLayer } from 'hooks/useDataLayer';
import { BannerText, BannerHide } from '../primitives';
import ExternalLink from 'components/ExternalLink';

const StyledLink = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${themeGet('colors.greys.charcoal')};

  &:hover {
    color: ${themeGet('colors.greys.charcoal')};
  }
`;

const CampaignMessageLink = ({ title, children }) => {
  const cta = useSelector(getCampaignUrl);
  const label = useSelector(getCampaignUrlLabel);
  const tracking = useSelector(getCampaignTracking);
  const { emitInteractionEvent } = useDataLayer();

  const handleOnClick = () => {
    const type = tracking?.category || 'Campaign Blue Banner';
    const value = tracking?.action || `${title} Selected`;
    const customAttributes = {};
    if (tracking?.label) {
      customAttributes.user_event_label = tracking?.label;
    }
    emitInteractionEvent({ type, value, customAttributes });
  };

  if (!cta) return <Fragment>{children}</Fragment>;

  return (
    <StyledLink href={cta} onClick={handleOnClick} target="_blank" rel="noopener noreferrer" aria-label={title} data-testid="link-wrapper">
      {children}&nbsp;
      <BannerHide xs>
        {label ? (
          <BannerText textDecoration="underline" data-testid="campaign-cta-label">
            {label}
          </BannerText>
        ) : (
          <Icon name="chevronRight" data-testid="campaign-cta-label" />
        )}
      </BannerHide>
    </StyledLink>
  );
};

CampaignMessageLink.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CampaignMessageLink.defaultProps = {
  title: undefined,
};

export default CampaignMessageLink;
