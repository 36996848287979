/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';

export default function useIsInViewport(ref?: React.RefObject<HTMLElement>) {
  const [isInViewport, setIsInViewport] = useState(false);

  const observer = useMemo(() => {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      return new IntersectionObserver(([entry]) => setIsInViewport(entry.isIntersecting));
    }
    return null;
  }, [ref]);

  useEffect(() => {
    if (ref?.current) observer?.observe(ref.current);
    return () => observer?.disconnect();
  }, []);

  return isInViewport;
}
