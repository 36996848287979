import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { rem } from 'polished';
import { Text, Hide } from '@qga/roo-ui/components';

export const BannerHide = styled(Hide)`
  display: flex;
`;

export const BannerText = styled(Text)`
  text-transform: uppercase;
  font-weight: ${themeGet('fontWeights.bold')};
  font-size: ${rem('14px')};
  text-align: center;
`;
